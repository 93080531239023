<template>
  <div class="sign-up-page">
    <!-- HomeIcon com setinha para voltar -->
    <HomeIcon :showArrow="true" to="/clubinho" />

    <form @submit.prevent="submitSignUp" class="form-container">
      <h1>Envie Sua Comprovação de Estudante</h1>

      <p class="description">
        Envie seus documentos para análise da equipe Sunset Arena. Assim que confirmarmos as informações, entraremos em contato por e-mail ou WhatsApp para finalizar sua assinatura do Clubinho.
      </p>

      <!-- Loader para o envio do formulário -->
      <div v-if="loading" class="loader-container">
        <div class="loader"></div>
        <p>Enviando dados...</p>
      </div>

      <div v-if="!loading">
        <!-- Campo Nome (Pré-preenchido e desabilitado) -->
        <div class="form-group">
          <label for="nome">Nome</label>
          <input
            v-model="form.nome"
            type="text"
            id="nome"
            disabled
          />
        </div>

        <!-- Campo E-mail (Pré-preenchido e desabilitado) -->
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            v-model="form.email"
            type="email"
            id="email"
            disabled
          />
        </div>

        <!-- Campo Telefone (Pré-preenchido e desabilitado) -->
        <div class="form-group">
          <label for="telefone">Telefone</label>
          <input
            v-model="form.telefone"
            type="text"
            id="telefone"
            disabled
          />
        </div>

        <!-- Campo para Upload de Arquivo -->
        <div class="form-group">
          <label for="arquivo">Documento</label>
          <input
            type="file"
            id="arquivo"
            @change="handleFileUpload"
            accept="*/*"
            required
          />
          <span v-if="form.errors.arquivo" class="error">{{ form.errors.arquivo }}</span>
        </div>

        <!-- Botão de Envio -->
        <button type="submit" class="button">Enviar</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, onMounted } from 'vue';
import axios from 'axios';
import HomeIcon from '@/components/HomeIcon.vue';

interface FormErrors {
  arquivo?: string;
}

interface FormState {
  nome: string;
  email: string;
  telefone: string;
  arquivo: File | null;
  errors: FormErrors;
}

export default defineComponent({
  name: 'SignUpPage',
  components: {
    HomeIcon,
  },
  setup() {
    const form = reactive<FormState>({
      nome: '',
      email: '',
      telefone: '',
      arquivo: null,
      errors: {},
    });

    const loading = ref(false);

    const fetchUserData = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/account`, {
      withCredentials: true, // Envia cookies junto com a requisição
    });

    const { nome, email, telefone } = response.data.user;

    if (!nome || !email || !telefone) {
      alert('Algumas informações estão ausentes ou incompletas. Você será redirecionado.');
      window.location.href = '/clubinho'; 
      return;
    }

    form.nome = nome;
    form.email = email;
    form.telefone = telefone;
  } catch (error) {
    console.error('Erro ao buscar dados do usuário:', error);
    alert('Ocorreu um erro ao carregar seus dados. Por favor, tente novamente.');
    window.location.href = '/clubinho'; 
  }
};


    const handleFileUpload = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        form.arquivo = target.files[0];
        // Limpar erros anteriores
        form.errors.arquivo = '';
      }
    };

    const validateForm = (): boolean => {
      let isValid = true;

      if (!form.arquivo) {
        form.errors.arquivo = 'O arquivo é obrigatório.';
        isValid = false;
      } else {
        const maxSizeInMB = 5;
        if (form.arquivo.size > maxSizeInMB * 1024 * 1024) {
          form.errors.arquivo = `O arquivo deve ter no máximo ${maxSizeInMB}MB.`;
          isValid = false;
        } else {
          form.errors.arquivo = '';
        }
      }

      return isValid;
    };

    const submitSignUp = async () => {
      if (!validateForm()) {
        return;
      }

      loading.value = true;

      try {
        const formData = new FormData();
        formData.append('nome', form.nome);
        formData.append('email', form.email);
        formData.append('telefone', form.telefone);
        if (form.arquivo) {
          formData.append('arquivo', form.arquivo);
        }

        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/send-comprovacao-estudante`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        alert('Assinatura realizada com sucesso!');
        window.location.href = '/'; 
      } catch (error) {
        console.error('Erro ao assinar o Clubinho:', error);
        alert('Ocorreu um erro ao realizar a assinatura. Por favor, tente novamente.');
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchUserData();
    });

    return {
      form,
      loading,
      handleFileUpload,
      submitSignUp,
    };
  },
});
</script>

<style scoped>
/* Estilos semelhantes aos da página anterior */

.sign-up-page {
  position: relative;
  width: 100%;
  padding: 60px;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00, #f01f84);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.form-container {
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
}

.description {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1rem;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

input[type="text"],
input[type="email"],
input[type="file"],
textarea,
select {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #f857a6;
  outline: none;
}

input[disabled],
textarea[disabled] {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 8px;
}

.button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: #f01f84;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(248, 87, 166, 0.3);
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #ff5858;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader-container p {
  font-size: 1.3rem;
  color: #000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .sign-up-page {
    padding: 40px;
  }

  .form-container {
    padding: 20px;
    width: 90%;
  }

  h1 {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .form-group label,
  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 0.9rem;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .button {
    font-size: 1rem;
    padding: 10px;
  }
}
</style>
