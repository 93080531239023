<template>
  <div>
    <Header />
    <div class="sponsors-page">
      <div class="overlay">
        <h1>Conheça nossos Patrocinadores</h1>
        
        <div v-if="loading" class="loader-container">
          <div class="loader"></div>
          <p>Carregando dados...</p>
        </div>

        <div v-else>
          <div class="sponsors">
            <div v-for="sponsor in sponsors" :key="sponsor.id" class="sponsor">
              <!-- Exibe a imagem usando diretamente o campo 'imagem' que já contém a URL completa -->
              <img :src="sponsor.imagem" alt="imagem do patrocinador" />
              <div class="sponsor-details">
                <h3>{{ sponsor.nome }}</h3>
                <p>{{ sponsor.descricao }}</p>
              </div>
            </div>
          </div>

          <!-- Espaço para parcerias -->
          <div class="partnership-invite">
            <h2>Quer ser um de nossos parceiros?</h2>
            <p>
              Envie uma mensagem para nós e vamos conversar sobre como podemos
              crescer juntos. Para mais informações, entre em contato por e-mail:
            </p>
            <p class="email">arenasunsetlavras@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import { defineComponent, onMounted, ref } from "vue";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

interface Patrocinadores {
  id: number;
  nome: string;
  descricao: string;
  imagem: string; // O campo contendo a URL completa da imagem
}

export default defineComponent({
  name: "SponsorsPage",
  components: {
    Header,
    Footer,
  },

  setup() {
    const sponsors = ref<Patrocinadores[]>([]);
    const loading = ref(true); // Variável de carregamento

    const fetchSponsors = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/patrocinadores`);
        sponsors.value = response.data;
        loading.value = false;
      } catch (error) {
        console.error("Erro ao buscar patrocinadores:", error);
      } 
    };

    onMounted(() => {
      fetchSponsors();
    });

    return {
      sponsors,
      loading, // Retorna o estado de carregamento
    };
  },
});
</script>

<style scoped>
.sponsors-page {
  position: relative;
  width: 100%;
  background: url('@/assets/quadras.jpg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.overlay {
  position: relative;
  width: 100%;
  padding: 40px 20px;
  min-height: 70vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  font-size: 2.5em;
  margin-bottom: 40px;
  text-align: center;
}

/* Loader */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader-container p {
  color: white;
  margin-top: 20px;
  font-size: 1.5em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sponsors {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
}

.sponsor {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  text-align: center; /* Centraliza o texto */
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os itens horizontalmente */
  justify-content: center; /* Centraliza os itens verticalmente */
  transition: transform 0.3s, box-shadow 0.3s;
}

.sponsor-details {
  text-align: center; /* Centraliza o texto dos detalhes */
}

.sponsor img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin-bottom: 15px;
}

.sponsor-details h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.sponsor-details p {
  font-size: 1.1em;
  text-align: center; /* Centraliza o texto da descrição */
}


/* Seção de convite para parcerias */
.partnership-invite {
  margin: 50px auto 0;
  padding: 20px;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border-radius: 15px;
  text-align: center;
  color: white;
  width: 100%;
  max-width: 800px;
}

.partnership-invite h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.partnership-invite p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.email {
  font-size: 1.5em;
  font-weight: bold;
  color: #ffffff;
}

@media (max-width: 768px) {

  .sponsors-page {
    background: none; /* Remove o fundo padrão em telas menores */
  }
  
  .sponsors-page::before {
  content: ''; /* Pseudo-elemento para o fundo fixo */
  position: fixed; /* Faz com que a imagem permaneça fixa no viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Imagem de fundo */
  background-image: url('@/assets/quadras.jpg'); /* Substitua pelo caminho correto */
  background-size: cover; /* Faz a imagem cobrir toda a área */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat;

  z-index: -1; /* Coloca a imagem atrás de todo o conteúdo */
}

  .overlay {
    padding: 20px 10px; /* Reduz o espaçamento no mobile */
  }
  
  .overlay{
    padding: 20px;
  }

}

@media (max-width: 480px) {
  .sponsors-page::before {
    position: fixed; /* Mantém o fundo fixo no mobile */
    background-size: cover; /* Faz a imagem cobrir o viewport */
    background-position: center; /* Centraliza a imagem no mobile */
  }
}

</style>
