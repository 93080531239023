<template>
  <div class="clubinho-payment-page">
    <!-- Ícone para voltar -->
    <HomeIcon :showArrow="true" to="/clubinho" />

    <!-- Loader global -->
    <div class="loader-container" v-if="loading">
      <div class="loader"></div>
      <p>Carregando dados...</p>
    </div>

    <!-- Conteúdo principal -->
    <div class="content-container" v-else>
      <h1>Finalize sua Assinatura do Clubinho Individual</h1>

      <p class="description">
        Parabéns por dar mais um passo para se tornar parte do Clubinho Individual! 
        Clique no botão abaixo para finalizar sua assinatura e aproveitar todos os benefícios exclusivos para você.
      </p>

      <div class="details">
        <p><span>Plano:</span> Clubinho Individual</p>
        <p><span>Início:</span> {{ startDate }}</p>
        <p><span>Fim:</span> {{ endDate }}</p>
      </div>

      <!-- Botão para ir ao pagamento -->
      <button @click="goToPayment" class="button">Ir para o Pagamento</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import HomeIcon from '@/components/HomeIcon.vue';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';

// Carregue sua public key do Stripe
const stripePromise = loadStripe('pk_live_51Pw6F4KJmK61rZjc8fMMEQtTQAVLpgVVK3SPeFBf96Rpp40thcF79DFSXD8F6mbfmckzQy3YATb2DcM99Mj9JSmS00xIP1C5QV');

export default defineComponent({
  name: 'ClubinhoIndividualPaymentPage',
  components: {
    HomeIcon,
  },
  setup() {
    const user = ref<any>(null);
    const startDate = ref<string>('');
    const endDate = ref<string>('');
    const loading = ref<boolean>(true); // Controla o loader global

    // Ao clicar no botão de pagamento
    const goToPayment = async () => {
      // Mostra o loader enquanto criamos a sessão no Stripe
      loading.value = true;
      try {
        // Ajuste o endpoint conforme seu backend
        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/stripe/create-checkout-session-clubinho`,
          {
            product: 4, // ID do plano individual (exemplo)
            userId: user.value.id,
            startDate: startDate.value,
            endDate: endDate.value,
          },
          { withCredentials: true }
        );

        const stripe = await stripePromise;
        if (stripe) {
          await stripe.redirectToCheckout({ sessionId: response.data.id });
          // Se der certo, o usuário será redirecionado e não voltará a este estado
        } else {
          console.error('Erro ao inicializar o Stripe.');
          loading.value = false;
        }
      } catch (error) {
        console.error('Erro ao criar a sessão do Stripe:', error);
        loading.value = false;
      }
    };

    // Busca dados do usuário + define datas
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/account`,
          { withCredentials: true }
        );
        user.value = response.data.user;

        const today = dayjs();
        startDate.value = today.format('DD/MM/YYYY');
        endDate.value = today.add(1, 'month').format('DD/MM/YYYY');

      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
      } finally {
        // Ao finalizar o fetch, some o loader
        loading.value = false;
      }
    };

    onMounted(() => {
      // Inicia a página carregando...
      loading.value = true;
      fetchUserData();
    });

    return {
      user,
      startDate,
      endDate,
      loading,
      goToPayment,
    };
  },
});
</script>

<style scoped>
.clubinho-payment-page {
  position: relative;
  width: 100%;
  padding: 60px;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00, #f01f84);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

/* Loader container */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 16px solid #f3f3f3; /* Cor do "fundo" do loader */
  border-top: 16px solid #3498db; /* Cor da parte animada */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}
.loader-container p { 
  font-size: 1.5rem;
  margin-top: 10px;
  color: #ffffff;
  text-align: center; 
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.content-container {
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
}

.description {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1rem;
  color: #333;
}

.details {
  margin-bottom: 20px;
  text-align: left;
}

.details p {
  font-size: 1rem;
  color: #333;
  margin: 5px 0;
  font-weight: bold;
}

.details span {
  color: #f01f84;
  font-weight: 700;
}

.button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: #f01f84;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(248, 87, 166, 0.3);
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #ff5858;
}

/* Responsividade */
@media (max-width: 768px) {
  .clubinho-payment-page {
    padding: 40px;
  }

  .content-container {
    padding: 20px;
    width: 90%;
  }

  h1 {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .details p {
    font-size: 0.9rem;
  }

  .button {
    font-size: 1rem;
    padding: 10px;
  }
}
</style>
