<template>
  <div class="register-container">
    <HomeIcon :showArrow="true" to="/login"/>
    <div class="register-box">
      <h1>Cadastro</h1>

      <!-- Loader durante o envio do email de confirmação -->
      <div v-if="loading" class="loader-container">
        <div class="loader"></div>
        <p>Enviando dados...</p>
      </div>

      <!-- Formulário de cadastro -->
      <form v-else @submit.prevent="sendConfirmationEmail">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            v-model="state.email"
            @blur="validateField('email')"
          />
          <span v-if="state.errors.email" class="error">{{ state.errors.email }}</span>
        </div>
        <button type="submit">Enviar Email de Confirmação</button>
      </form>

      <p v-if="!loading">
        Já tem uma conta? <router-link to="/login">Faça seu login</router-link>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import axios from 'axios';
import * as yup from 'yup';
import { useRouter } from 'vue-router';
import HomeIcon from '../../components/HomeIcon.vue';

export default defineComponent({
  name: 'Register',
  components: {
    HomeIcon,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false); // Estado do loader

    const state = reactive({
      email: '',
      errors: {
        email: '',
      } as Record<string, string>,
    });

    const schema = yup.object().shape({
      email: yup
        .string()
        .email('Email inválido')
        .max(60, 'O email deve ter no máximo 60 caracteres')
        .required('Email é obrigatório'),
    });

    const validateField = async (field: string) => {
      try {
        await schema.validateAt(field, state);
        state.errors[field] = '';
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          state.errors[field] = err.message;
        }
      }
    };

    const validate = async () => {
      try {
        await schema.validate({ ...state }, { abortEarly: false });
        state.errors = { email: '' };
        return true;
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const newErrors: Record<string, string> = { email: '' };
          err.inner.forEach((error) => {
            if (error.path) {
              newErrors[error.path] = error.message;
            }
          });
          state.errors = newErrors;
        }
        return false;
      }
    };

    const sendConfirmationEmail = async () => {
      if (!(await validate())) {
        return;
      }

      loading.value = true; // Ativa o loader
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/send-confirmation-email`, {
          email: state.email,
        });

        alert('Email de confirmação enviado! Verifique sua caixa de entrada.');
        router.push('/login');
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error === 'Email já cadastrado.') {
            state.errors.email = 'Este email já foi cadastrado. Por favor, utilize outro email ou faça login.';
          } else {
            alert('Erro ao enviar email de confirmação. Por favor, tente novamente.');
          }
        } else {
          console.error('Erro ao enviar email de confirmação:', error);
          alert('Erro ao enviar email de confirmação. Por favor, tente novamente.');
        }
      } finally {
        loading.value = false; // Desativa o loader
      }
    };

    return {
      state,
      loading,
      sendConfirmationEmail,
      validateField,
    };
  },
});
</script>

<style scoped>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #ff6a00, #f01f84);
  padding: 20px;
  min-height: 100vh;
}

.register-box {
  background: white;
  margin: 30px;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 450px;
}

h1 {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 2.2rem;
  color: #f01f84;
}

.form-group {
  margin-bottom: 0.7rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

button {
  text-transform: uppercase;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 1rem;
  font-weight: bold;
}

button:hover:not(:disabled) {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

p {
  margin-top: 1.5rem;
  font-size: 14px;
}

router-link {
  color: #f01f84;
  text-decoration: none;
}

router-link:hover {
  text-decoration: underline;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/* Loader Centralizado */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p {
  font-size: 1.3rem; /* Texto mais proporcional */
  color: #000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .register-container {
    padding: 40px;
  }

  .register-box {
    padding: 30px;
    width: 90%;
    margin: 20px;
  }

  h1 {
    font-size: 1.8rem;
  }

  label {
    font-size: 0.9rem;
  }

  input {
    font-size: 0.9rem;
    padding: 0.6rem;
  }

  button {
    padding: 0.6rem;
    font-size: 1rem;
  }

  .loader-container p {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .register-container {
    padding: 20px;
  }

  .register-box {
    padding: 30px;
    width: 100%;
  }

  h1 {
    font-size: 1.5rem;
  }

  label {
    font-size: 0.8rem;
  }

  input {
    font-size: 0.85rem;
    padding: 0.5rem;
  }

  button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  p {
    font-size: 0.85rem;
  }

  .loader {
    width: 80px;
    height: 80px;
    border-width: 12px;
  }

  .loader-container p {
    font-size: 1rem;
  }
}

</style>
