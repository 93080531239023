<template>
    <div class="create-clubinho-page" @click="handleOutsideClick">
      <HomeIcon :showArrow="true" to="/adm-dashboard" />
  
      <div class="container">
        <h3>CRIAR NOVO CLUBINHO</h3>
  
        <!-- Campo de busca / Input do email do usuário -->
        <div class="search-section">
          <input
            v-model="searchQuery"
            :disabled="!!selectedUser"
            @input="onInput"
            @focus="showDropdown = !selectedUser"
            placeholder="Digite o nome ou email do usuário"
            class="search-input"
          />
  
          <!-- Se o usuário estiver selecionado, exibir botão para trocar -->
          <button
            v-if="selectedUser"
            class="change-user-btn"
            @click="clearSelectedUser"
          >
            Trocar Usuário
          </button>
  
          <!-- Loader da busca de usuários -->
          <div v-if="loadingUsers" class="loader-container">
            <div class="loader"></div>
            <p>Carregando usuários...</p>
          </div>
  
          <!-- Dropdown de usuários (só aparece se não tiver um user selecionado) -->
          <ul
            v-if="showDropdown && !loadingUsers && !selectedUser && filteredUsers.length > 0"
            class="user-dropdown"
          >
            <li
              v-for="userItem in filteredUsers"
              :key="userItem.id"
              @click.stop="selectUser(userItem)"
            >
              {{ userItem.nome }} - {{ userItem.email }}
            </li>
          </ul>
  
          <!-- Mensagem "Nenhum usuário encontrado" -->
          <p
            v-if="!loadingUsers && !selectedUser && searchQuery.trim().length > 0 && filteredUsers.length === 0"
            class="no-results"
          >
            Nenhum usuário encontrado.
          </p>
        </div>
  
        <!-- Tipo do Clubinho -->
        <div class="clubinho-type-section">
          <h4>Tipo de Clubinho:</h4>
          <select v-model="clubinhoTipo" class="clubinho-select">
            <option disabled value="">Selecione o tipo</option>
            <option value="4">Individual</option>
            <option value="3">Família</option>
            <option value="5">Estudante</option>
            <option value="6">Aluno</option>
          </select>
        </div>
  
        <!-- Datas (fixas: hoje e 1 mês depois) -->
        <div class="dates-section">
          <h4>Datas</h4>
          <p>Data Início (hoje): {{ formatDate(dataInicio) }}</p>
          <p>Data Fim (máximo 1 mês depois): {{ formatDate(dataFim) }}</p>
        </div>
  
        <!-- Botão Criar -->
        <button
          class="create-button"
          :disabled="!isFormValid"
          @click="createClubinho"
        >
          Criar Clubinho
        </button>
  
        <!-- Loader de criação -->
        <div v-if="creating" class="loader-container">
          <div class="loader"></div>
          <p>Criando o clubinho...</p>
        </div>
  
        <!-- Mensagem de erro (caso haja) -->
        <p v-if="errorMessage" class="error-msg">{{ errorMessage }}</p>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed } from "vue";
  import axios from "axios";
  import dayjs from "dayjs";
  import HomeIcon from "@/components/HomeIcon.vue";
  
  interface User {
    id: number;
    nome: string;
    email: string;
  }
  
  export default defineComponent({
    name: "CreateClubinho",
    components: { HomeIcon },
    setup() {
      const searchQuery = ref("");
      const users = ref<User[]>([]);
      const selectedUser = ref<User | null>(null);
  
      const clubinhoTipo = ref("");
      const dataInicio = ref(dayjs().format("YYYY-MM-DD")); // hoje
      const dataFim = ref(dayjs().add(1, "month").format("YYYY-MM-DD")); // +1 mês
  
      const loadingUsers = ref(false);
      const creating = ref(false);
      const errorMessage = ref("");
      const showDropdown = ref(false);
  
      let debounceTimeout: ReturnType<typeof setTimeout> | null = null;
  
      // Buscar usuários do backend
      const searchUsers = async () => {
        loadingUsers.value = true;
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_URL}/searchClients?query=${searchQuery.value}`
          );
          users.value = response.data;
        } catch (err) {
          console.error("Erro ao buscar usuários:", err);
        } finally {
          loadingUsers.value = false;
        }
      };
  
      // Dispara busca com debounce
      const onInput = () => {
        if (debounceTimeout) clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(async () => {
          if (!searchQuery.value.trim()) {
            users.value = [];
            return;
          }
          await searchUsers();
        }, 300);
      };
  
      // Filtra localmente, se quiser
      const filteredUsers = computed(() => {
        const query = searchQuery.value.toLowerCase().trim();
        return users.value.filter(u =>
          u.nome.toLowerCase().includes(query) ||
          u.email.toLowerCase().includes(query)
        );
      });
  
      // Selecionar usuário
      const selectUser = (userItem: User) => {
        selectedUser.value = userItem;
        searchQuery.value = userItem.email; // coloca o email no input
        showDropdown.value = false;
      };
  
      // Limpa a seleção, reabilita input
      const clearSelectedUser = () => {
        selectedUser.value = null;
        searchQuery.value = "";
        users.value = [];
        showDropdown.value = true;
      };
  
      // Valida form
      const isFormValid = computed(() => {
        return (
          !!selectedUser.value &&
          !!clubinhoTipo.value &&
          !!dataInicio.value &&
          !!dataFim.value
        );
      });
  
      // Cria o Clubinho
      const createClubinho = async () => {
        if (!selectedUser.value) return;
        creating.value = true;
        errorMessage.value = "";
  
        try {
          const payload = {
            idUsuario: selectedUser.value.id,
            planId: clubinhoTipo.value,
            dataInicio: dataInicio.value,
            dataFim: dataFim.value,
          };
  
          await axios.post(
            `${process.env.VUE_APP_BACKEND_URL}/clubinhos`,
            payload,
            { withCredentials: true }
          );
  
          // Ao criar com sucesso, mostramos um alert
          window.alert("Clubinho criado com sucesso!");
  
          // resetar
          selectedUser.value = null;
          searchQuery.value = "";
          clubinhoTipo.value = "";
          dataInicio.value = dayjs().format("YYYY-MM-DD");
          dataFim.value = dayjs().add(1, "month").format("YYYY-MM-DD");
        } catch (error: any) {
          console.error("Erro ao criar clubinho:", error);
          errorMessage.value =
            error.response?.data?.message || "Erro ao criar o clubinho.";
        } finally {
          creating.value = false;
        }
      };
  
      // Formata datas
      const formatDate = (dateStr: string) => {
        return dayjs(dateStr).format("DD/MM/YYYY");
      };
  
      // Fecha dropdown se clicar fora
      const handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (!target.closest(".search-section")) {
          showDropdown.value = false;
        }
      };
  
      return {
        searchQuery,
        users,
        selectedUser,
        clubinhoTipo,
        dataInicio,
        dataFim,
        loadingUsers,
        creating,
        errorMessage,
        showDropdown,
  
        onInput,
        filteredUsers,
        selectUser,
        clearSelectedUser,
        isFormValid,
        createClubinho,
        formatDate,
        handleOutsideClick,
      };
    },
  });
  </script>
  
  <style scoped>
  .create-clubinho-page {
  padding: 40px;
  min-height: 100vh;
  background: linear-gradient(135deg, #6EE7B7 0%, #3B82F6 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background: #f0f9ff;
  max-width: 700px;
  width: 100%;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
}

.search-section {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.change-user-btn {
  margin-top: 10px;
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;
}

.change-user-btn:hover {
  background: linear-gradient(135deg, #1d4ed8 0%, #2563eb 100%);
}

.user-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  background: white;
  list-style: none;
  margin: 5px 0 0;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.user-dropdown li {
  padding: 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.user-dropdown li:hover {
  background: #f0faff;
}

.clubinho-type-section,
.dates-section {
  text-align: left;
  margin-top: 20px;
  color: #333;
}

.clubinho-select {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #ddd;
  font-size: 1rem;
  margin-top: 10px;
  background-color: white; /* Define fundo branco */
  color: #333; /* Define cor do texto */
  appearance: none; /* Remove estilos padrão do navegador */
}

.clubinho-select:focus {
  border-color: #2563eb; /* Destaca a borda ao focar */
  outline: none;
}

.create-button {
  margin-top: 30px;
  width: 100%;
  padding: 14px 20px;
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}

.create-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.create-button:hover:not(:disabled) {
  background: linear-gradient(135deg, #1d4ed8 0%, #2563eb 100%);
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.loader {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #2563eb;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-msg {
  color: #dc3545;
  margin-top: 15px;
  font-weight: bold;
}

.no-results {
  margin-top: 8px;
  color: #888;
  font-size: 0.9rem;
}

/* Responsividade */
@media (max-width: 600px) {
  .create-clubinho-page {
    padding: 20px;
  }
  .container {
    max-width: 100%;
    padding: 20px;
  }
  h3 {
    font-size: 1.4rem;
  }
  .create-button {
    font-size: 1rem;
    padding: 10px;
  }
}

  </style>
  