import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    selectedDate: null as Date | null,
    selectedWeekDay: '' as string,
    selectedDates: [] as Date[],
    selectedDayIndex: null as number | null,
    isAuthenticated: false, // Estado para gerenciar a autenticação
    usuario: null as any, // Adicionando o estado de usuário
  },
  mutations: {
    setSelectedDate(state, date: Date) {
      state.selectedDate = date;
    },
    setSelectedWeekDay(state, day: string) {
      state.selectedWeekDay = day;
    },
    setSelectedDates(state, dates: Date[]) {
      state.selectedDates = dates;
    },
    setAuthentication(state, status: boolean) {
      state.isAuthenticated = status;
    },
    setSelectedDayIndex(state, number: number) {
      state.selectedDayIndex = number;
    },
    setUsuario(state, usuario: any) { // Adicionando a mutação de usuário
      state.usuario = usuario;
      state.isAuthenticated = !!usuario; // Atualiza isAuthenticated com base no usuário
    },
    clearAuthentication(state) {
      state.isAuthenticated = false;
      state.usuario = null;
    },
  },
  actions: {
    async fetchUsuario({ commit }) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/account`, { withCredentials: true });
        if (response.data.isAuthenticated) {
          commit('setUsuario', response.data.user);
        } else {
          commit('clearAuthentication');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        commit('clearAuthentication');
      }
    },
  },
  modules: {},
});
