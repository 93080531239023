<template>
  <div class="create-teacher-page">
    <HomeIcon :showArrow="true" to="adm-dashboard" />

    <form @submit.prevent="createTeacher" class="form-container">
      <h1>Criar Novo Professor</h1>

      <!-- Loader para a criação de professor -->
      <div v-if="loadingCreate" class="loader-container">
        <div class="loader"></div>
        <p>Enviando dados...</p>
      </div>

      <div v-else>
        <div class="form-group">
          <label for="nome">Nome do Professor</label>
          <input
            v-model="state.nome"
            type="text"
            id="nome"
            placeholder="Digite o nome do professor"
            @blur="validateField('nome')"
            maxlength="30"
          />
          <span v-if="state.errors.nome" class="error">{{ state.errors.nome }}</span>
        </div>

        <div class="form-group">
          <label for="especialidade">Especialidade</label>
          <input
            v-model="state.especialidade"
            type="text"
            id="especialidade"
            placeholder="Digite a especialidade do professor"
            @blur="validateField('especialidade')"
            maxlength="50"
          />
          <span v-if="state.errors.especialidade" class="error">{{ state.errors.especialidade }}</span>
        </div>

        <div class="form-group">
          <label for="telefone">Telefone</label>
          <!-- Removido o @input="onPhoneInput" para não conflitar com o v-model -->
          <input
            v-model="telefoneMasked"
            type="tel"
            id="telefone"
            placeholder="(XX) XXXXX-XXXX"
            @blur="validateField('telefone')"
            maxlength="15"
          />
          <span v-if="state.errors.telefone" class="error">{{ state.errors.telefone }}</span>
        </div>

        <div class="form-group">
          <label for="descricao">Descrição</label>
          <textarea
            v-model="state.descricao"
            id="descricao"
            placeholder="Digite uma descrição"
            rows="4"
            @blur="validateField('descricao')"
            maxlength="200"
          ></textarea>
          <span v-if="state.errors.descricao" class="error">{{ state.errors.descricao }}</span>
        </div>

        <button type="submit" class="button">Criar Professor</button>
      </div>
    </form>

    <!-- Tabela de professores -->
    <div class="teachers-list">
      <div>
        <h2>Professores Cadastrados</h2>
        <div v-if="loadingData" class="loader-container">
          <div class="loader"></div>
          <p>Carregando dados...</p>
        </div>
        <div v-else>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Especialidade</th>
                <th>Telefone</th>
                <th>Descrição</th>
                <th class="acoes-header">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="teacher in teachers" :key="teacher.id">
                <td>{{ teacher.nome }}</td>
                <td>{{ teacher.especialidade }}</td>
                <td>{{ formatPhone(teacher.telefone) }}</td>
                <td>{{ teacher.descricao }}</td>
                <td class="acoes-cell">
                  <div v-if="loadingDelete && deletingTeacherId === teacher.id" class="loader-container">
                    <div class="loader small"></div>
                  </div>
                  <div v-else>
                    <button @click="confirmDeleteTeacher(teacher.id)" class="btn-delete" title="Excluir Professor">
                      <font-awesome-icon icon="trash" class="icon-trash" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal de confirmação para excluir professor -->
    <div v-if="showConfirmation" class="confirmation-modal">
      <div class="modal-content">
        <h3>Confirmar Exclusão</h3>
        <p>Deseja realmente excluir este professor?</p>
        <div class="modal-actions">
          <button @click="cancelDeleteTeacher" class="btn-cancel">Cancelar</button>
          <button @click="confirmDelete" class="btn-confirm">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import { defineComponent, reactive, ref, computed, watch, onMounted } from 'vue';
import * as yup from 'yup';
import HomeIcon from '@/components/HomeIcon.vue';

interface Teacher {
  id: number;
  nome: string;
  especialidade: string;
  telefone: string; // Armazenado sem máscara (ex: "553198882951")
  descricao: string;
}

interface Errors {
  nome?: string;
  especialidade?: string;
  telefone?: string;
  descricao?: string;
}

interface State {
  nome: string;
  especialidade: string;
  telefone: string; // Armazena os dígitos com o código do país (ex: "55" + dígitos)
  descricao: string;
  errors: Errors;
}

export default defineComponent({
  name: 'CreateTeacherPage',
  components: { HomeIcon },
  setup() {
    const teachers = ref<Teacher[]>([]);
    const showConfirmation = ref(false);
    const deletingTeacherId = ref<number | null>(null);
    const loadingData = ref(true);
    const loadingCreate = ref(false);
    const loadingDelete = ref(false);

    // Estado reativo do formulário com tipagem completa (incluindo errors)
    const state = reactive<State>({
      nome: '',
      especialidade: '',
      telefone: '',
      descricao: '',
      errors: {} as Errors
    });

    // Schema do Yup para validação dos campos
    const teacherSchema = yup.object().shape({
      nome: yup
        .string()
        .required('O nome é obrigatório')
        .max(30, 'O nome deve ter no máximo 30 caracteres'),
      especialidade: yup
        .string()
        .required('A especialidade é obrigatória')
        .max(50, 'A especialidade deve ter no máximo 50 caracteres'),
      telefone: yup
        .string()
        .required('O telefone é obrigatório')
        .matches(/^55\d{10,11}$/, 'Telefone inválido. Deve conter 12 ou 13 dígitos (incluindo 55).'),
      descricao: yup
        .string()
        .required('A descrição é obrigatória')
        .max(200, 'A descrição deve ter no máximo 200 caracteres')
    });

    // Validação de um campo individual com Yup  
    // O parâmetro "field" é tipado como keyof Errors (ou seja: "nome" | "especialidade" | "telefone" | "descricao")
    const validateField = async (field: keyof Errors) => {
      try {
        await teacherSchema.validateAt(field, state);
        state.errors[field] = '';
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          state.errors[field] = error.message;
        }
      }
    };

    // Validação completa do formulário
    const validate = async () => {
      try {
        await teacherSchema.validate(
          {
            nome: state.nome,
            especialidade: state.especialidade,
            telefone: state.telefone,
            descricao: state.descricao
          },
          { abortEarly: false }
        );
        state.errors = {} as Errors;
        return true;
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          const errors: Errors = {};
          error.inner.forEach((err: any) => {
            if (err.path) {
              errors[err.path as keyof Errors] = err.message;
            }
          });
          state.errors = errors;
        }
        return false;
      }
    };

    // Função para criar um novo professor
    const createTeacher = async () => {
      const isValid = await validate();
      if (!isValid) {
        return;
      }
      loadingCreate.value = true;
      console.log(state)
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/professor`, {
          nome: state.nome,
          especialidade: state.especialidade,
          telefone: state.telefone, // Salvo sem máscara (ex: "553198882951")
          descricao: state.descricao
        });
        alert('Professor criado com sucesso!');
        // Limpa os campos do formulário
        state.nome = '';
        state.especialidade = '';
        state.telefone = '';
        state.descricao = '';
        await fetchTeachers();
      } catch (error) {
        console.error('Erro ao criar professor:', error);
        alert('Erro ao criar professor');
      } finally {
        loadingCreate.value = false;
      }
    };

    // Computed property para gerenciar o telefone com máscara
    const telefoneMasked = computed({
      get() {
        const phone = state.telefone;
        if (!phone) return '';
        let cleaned = phone.replace(/\D/g, '');
        if (cleaned.startsWith('55')) {
          cleaned = cleaned.slice(2);
        }
        if (cleaned.length > 10) { // Formato (XX) XXXXX-XXXX
          return `(${cleaned.slice(0,2)}) ${cleaned.slice(2,7)}-${cleaned.slice(7,11)}`;
        } else { // Formato (XX) XXXX-XXXX
          return `(${cleaned.slice(0,2)}) ${cleaned.slice(2,6)}-${cleaned.slice(6,10)}`;
        }
      },
      set(value: string) {
        let cleaned = value.replace(/\D/g, '');
        if (cleaned.startsWith('55')) {
          cleaned = cleaned.slice(2);
        }
        if (cleaned.length > 11) {
          cleaned = cleaned.slice(0, 11);
        }
        state.telefone = '55' + cleaned;
      }
    });

    // Watch para sincronizar telefoneMasked com state.telefone
    watch(telefoneMasked, (newVal) => {
      const digits = newVal.replace(/\D/g, '');
      if (digits.length > 11) {
        state.telefone = '55' + digits.slice(0, 11);
      } else {
        state.telefone = '55' + digits;
      }
    });

    // Função para formatar o telefone para exibição na tabela
    const formatPhone = (phone: string): string => {
      const cleaned = phone.replace(/\D/g, '');
      if (cleaned.length === 13) {
        return `+55 (${cleaned.slice(2, 4)}) ${cleaned.slice(4, 9)}-${cleaned.slice(9, 13)}`;
      } else if (cleaned.length === 12) {
        return `+55 (${cleaned.slice(2, 4)}) ${cleaned.slice(4, 8)}-${cleaned.slice(8, 12)}`;
      }
      return phone;
    };

    // Busca os professores cadastrados
    const fetchTeachers = async () => {
      loadingData.value = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/professor`);
        teachers.value = response.data;
        console.log(response.data);
      } catch (error) {
        console.error('Erro ao buscar professores:', error);
      } finally {
        loadingData.value = false;
      }
    };

    // Funções para exclusão de professor
    const confirmDeleteTeacher = (id: number) => {
      deletingTeacherId.value = id;
      showConfirmation.value = true;
    };

    const cancelDeleteTeacher = () => {
      showConfirmation.value = false;
      deletingTeacherId.value = null;
    };

    const confirmDelete = async () => {
      if (deletingTeacherId.value) {
        loadingDelete.value = true;
        try {
          await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/professor/${deletingTeacherId.value}`);
          alert('Professor excluído com sucesso!');
          await fetchTeachers();
        } catch (error) {
          console.error('Erro ao excluir professor:', error);
          alert('Erro ao excluir professor');
        } finally {
          loadingDelete.value = false;
          showConfirmation.value = false;
          deletingTeacherId.value = null;
        }
      }
    };

    // (Caso queira manter uma função de input personalizada, ela pode ser utilizada em outra abordagem.
    // Neste exemplo, estamos utilizando apenas o v-model com a propriedade computada.)

    onMounted(() => {
      fetchTeachers();
    });

    return {
      state,
      teachers,
      telefoneMasked,
      createTeacher,
      validateField,
      showConfirmation,
      confirmDeleteTeacher,
      cancelDeleteTeacher,
      confirmDelete,
      loadingData,
      loadingCreate,
      loadingDelete,
      deletingTeacherId,
      formatPhone
    };
  }
});
</script>

<style scoped>
.create-teacher-page {
  position: relative;
  width: 100%;
  padding: 60px;
  min-height: 100vh;
  /* Fundo gradiente azul */
  background: linear-gradient(135deg, #6EE7B7, #3B82F6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.form-container {
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin-bottom: 30px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  /* Texto em azul escuro para contraste */
  color: #3B82F6;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

input,
select,
textarea {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #3B82F6;
  outline: none;
}

textarea {
  resize: none;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 8px;
}

.button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: bold;
  /* Botão azul */
  background-color: #3B82F6;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  /* Sombra adaptada ao tema azul */
  box-shadow: 0 2px 10px rgba(59, 130, 246, 0.3);
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #2563EB;
}

.teachers-list {
  width: 100%;
  max-width: 800px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

h2 {
  color: #3B82F6;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

thead th {
  background-color: #3B82F6;
  color: white;
  padding: 10px;
  border-bottom: 2px solid #2563EB;
}

tbody td {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  color: black;
}

tbody tr:hover {
  background-color: #f1f5f9;
}

.acoes-header {
  width: 100px;
}

.acoes-cell {
  position: relative;
}

.btn-delete {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-delete:focus {
  outline: none;
}

.icon-trash {
  color: #2563EB;
  font-size: 1.2rem;
  transition: transform 0.2s;
}

.btn-delete:hover .icon-trash {
  transform: scale(1.2);
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  color: black;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.modal-content h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: black;
}

.modal-content p {
  font-size: 1rem;
  margin-bottom: 30px;
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.btn-cancel,
.btn-confirm {
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-cancel {
  background-color: #ff4c4c;
  color: white;
}

.btn-confirm {
  background-color: #4300a2;
  color: white;
}

.btn-cancel:hover {
  background-color: #e04848;
}

.btn-confirm:hover {
  background-color: #4b00b2;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3B82F6;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p { 
  font-size: 1.5rem;
  margin-top: 10px;
  color: #ffffff;
  text-align: center; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .create-teacher-page {
    padding: 60px;
  }

  .form-container {
    padding: 20px;
    width: 90%;
  }

  .teachers-list {
    padding: 15px;
  }

  h1,
  h2 {
    font-size: 1.5rem;
  }

  .form-group label,
  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 0.9rem;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .icon-trash {
    font-size: 1.2rem;
  }
}

@media (max-width: 500px) {
  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
}
</style>
