<template>
  <div class="home-banner">
    <Swiper
      :modules="[Autoplay, Pagination]"
      loop
      :autoplay="{ delay: 5000, disableOnInteraction: false }"
      pagination
    >
    <!-- Slide 2: Imagem do evento sem overlay, com botão para inscrição posicionado na parte inferior -->
    <SwiperSlide>
        <div class="slide event-slide" :style="{ backgroundImage: 'url(' + eventImage + ')' }">
          <div class="event-content">
            <button @click="goToTournament">Inscreva-se no Torneio</button>
          </div>
        </div>
      </SwiperSlide>
      
      <!-- Slide 1: Imagem original com overlay e conteúdo -->
      <SwiperSlide>
        <div class="slide home-slide" :style="{ backgroundImage: 'url(' + homeImage + ')' }">
          <!-- Overlay aplicado somente neste slide -->
          <div class="overlay">
            <div class="content">
              <h1>Seja Bem-Vindo!</h1>
              <p>
                Conheça a Sunset Arena Lavras, muito mais que um complexo de quadras de esportes de areia!
                Venha nos visitar!
              </p>
              <button @click="goToCalendar">Agendar</button>
            </div>
          </div>
        </div>
      </SwiperSlide>

      
    </Swiper>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

// Importação das imagens usando ES Module
import homeImage from '@/assets/quadras.jpg';
import eventImage from '@/assets/event.jpeg';

export default defineComponent({
  name: 'HomeBanner',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const router = useRouter();

    const goToCalendar = () => {
      router.push({ name: 'Planos' });
    };

    const goToTournament = () => {
      window.open('https://letzplay.me/t/31399', '_blank');
    };

    return {
      homeImage,
      eventImage,
      goToCalendar,
      goToTournament,
      Autoplay,
      Pagination,
    };
  },
});
</script>

<style scoped>
/* Container principal mantendo o mesmo tamanho */
.home-banner {
  position: relative;
  width: 100%;
  height: 80vh;
}

/* Cada slide preenche todo o container */
.slide {
  width: 100%;
  height: 80vh;
  background-size: cover;
  background-position: center;
}

/* Slide 1: Com overlay aplicado */
.home-slide .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Conteúdo centralizado do slide original */
.content {
  background: white;
  padding: 32px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 90%;
}

.content h1 {
  font-size: 48px;
  color: #f01f84;
  margin-bottom: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.content p {
  font-size: 20px;
  color: #333;
  margin-bottom: 32px;
  font-weight: 400;
}

.content button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  border: none;
  padding: 16px 32px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.content button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

/* Slide do evento: sem overlay, botão reposicionado */
.event-slide {
  position: relative;
}

.event-content {
  position: absolute;
  bottom: 40px; /* Ajuste a distância conforme necessário */
  width: 100%;
  display: flex;
  justify-content: center;
}

.event-content button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  border: none;
  padding: 16px 32px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.event-content button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

/* Estilo responsivo */
@media (max-width: 768px) {
  .home-banner,
  .slide {
    height: 60vh;
  }
  .content {
    width: 90%;
    padding: 24px;
  }
  .content h1 {
    font-size: 32px;
  }
  .content p {
    font-size: 16px;
  }
  .content button,
  .event-content button {
    padding: 12px 24px;
    font-size: 14px;
  }
}
</style>
