<template>
    <div class="student-verification-page">
      <!-- HomeIcon com setinha para voltar -->
      <HomeIcon :showArrow="true" to="/clubinho" />
  
      <form @submit.prevent="submitVerification" class="form-container">
        <h1>Envie Sua Comprovação de Aluno</h1>
  
        <p class="description">
          Preencha os campos abaixo para comprovar sua matrícula. Assim que confirmarmos as informações, entraremos em contato por e-mail ou WhatsApp para finalizar sua assinatura do Clubinho.
        </p>
  
        <!-- Loader para o envio do formulário -->
        <div v-if="loading" class="loader-container">
          <div class="loader"></div>
          <p>Enviando dados...</p>
        </div>
  
        <div v-if="!loading">
          <!-- Campo Nome (Pré-preenchido e desabilitado) -->
          <div class="form-group">
            <label for="nome">Nome</label>
            <input
              v-model="form.nome"
              type="text"
              id="nome"
              disabled
            />
          </div>
  
          <!-- Campo E-mail (Pré-preenchido e desabilitado) -->
          <div class="form-group">
            <label for="email">E-mail</label>
            <input
              v-model="form.email"
              type="email"
              id="email"
              disabled
            />
          </div>
  
          <!-- Campo Telefone (Pré-preenchido e desabilitado) -->
          <div class="form-group">
            <label for="telefone">Telefone</label>
            <input
              v-model="form.telefone"
              type="text"
              id="telefone"
              disabled
            />
          </div>
  
          <!-- Campo Dia -->
          <div class="form-group">
            <label for="dia">Dia</label>
            <select v-model="form.dia" id="dia" required>
              <option disabled value="">Selecione o dia</option>
              <option v-for="diaOption in dias" :key="diaOption" :value="diaOption">{{ diaOption }}</option>
            </select>
            <span v-if="form.errors.dia" class="error">{{ form.errors.dia }}</span>
          </div>
  
          <!-- Campo Horário -->
          <div class="form-group">
            <label for="horario">Horário</label>
            <input
              v-model="form.horario"
              type="time"
              id="horario"
              required
            />
            <span v-if="form.errors.horario" class="error">{{ form.errors.horario }}</span>
          </div>
  
          <!-- Campo Professor da Turma -->
          <div class="form-group">
            <label for="professor">Professor da Turma</label>
            <input
              v-model="form.professor"
              type="text"
              id="professor"
              placeholder="Nome do professor"
              required
            />
            <span v-if="form.errors.professor" class="error">{{ form.errors.professor }}</span>
          </div>
  
          <!-- Botão de Envio -->
          <button type="submit" class="button">Enviar
          </button>
        </div>
      </form>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, reactive, ref, onMounted } from 'vue';
  import axios from 'axios';
  import HomeIcon from '@/components/HomeIcon.vue';
  import * as yup from 'yup';
  
  interface FormErrors {
    dia?: string;
    horario?: string;
    professor?: string;
  }
  
  interface FormState {
    nome: string;
    email: string;
    telefone: string;
    dia: string;
    horario: string;
    professor: string;
    errors: FormErrors;
  }
  
  export default defineComponent({
    name: 'StudentVerificationPage',
    components: {
      HomeIcon,
    },
    setup() {
      const form = reactive<FormState>({
        nome: '',
        email: '',
        telefone: '',
        dia: '',
        horario: '',
        professor: '',
        errors: {},
      });
  
      const loading = ref(false);
  
      const dias = ref<string[]>([
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
        'Domingo',
      ]);
  
      // Definição do esquema de validação com Yup
      const schema = yup.object().shape({
        dia: yup.string().required('O dia é obrigatório.'),
        horario: yup.string().required('O horário é obrigatório.'),
        professor: yup.string().trim().required('O nome do professor é obrigatório.'),
      });
  
      const fetchUserData = async () => {
        try {
          const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/account`, {
            withCredentials: true, // Envia cookies junto com a requisição
          });
  
          const { nome, email, telefone } = response.data.user;
  
          if (!nome || !email || !telefone) {
            alert('Algumas informações estão ausentes ou incompletas. Você será redirecionado.');
            window.location.href = '/clubinho';
            return;
          }
  
          form.nome = nome;
          form.email = email;
          form.telefone = telefone;
        } catch (error) {
          console.error('Erro ao buscar dados do usuário:', error);
          alert('Ocorreu um erro ao carregar seus dados. Por favor, tente novamente.');
          window.location.href = '/clubinho';
        }
      };
  
      const submitVerification = async () => {
        loading.value = true;
  
        try {
          // Remover erros anteriores de forma reativa
          Object.keys(form.errors).forEach((key) => {
            form.errors[key as keyof FormErrors] = '';
          });
  
          // Validar os campos usando o esquema Yup
          await schema.validate(
            {
              dia: form.dia,
              horario: form.horario,
              professor: form.professor,
            },
            { abortEarly: false } // Para coletar todos os erros
          );
  
          const payload = {
            nome: form.nome,
            email: form.email,
            telefone: form.telefone,
            dia: form.dia,
            horario: form.horario,
            professor: form.professor,
          };
  
          await axios.post(`${process.env.VUE_APP_BACKEND_URL}/send-comprovacao-aluno`, payload);
  
          alert('Comprovação enviada com sucesso!');
          window.location.href = '/';
        } catch (error: any) {
          if (error.name === 'ValidationError') {
            error.inner.forEach((err: yup.ValidationError) => {
              if (err.path && !form.errors[err.path as keyof FormErrors]) {
                form.errors[err.path as keyof FormErrors] = err.message;
              }
            });
          } else {
            // Erros do servidor ou de rede
            console.error('Erro ao enviar a comprovação:', error);
            alert('Ocorreu um erro ao enviar a comprovação. Por favor, tente novamente.');
          }
        } finally {
          loading.value = false;
        }
      };
  
      onMounted(() => {
        fetchUserData();
      });
  
      return {
        form,
        loading,
        dias,
        submitVerification,
      };
    },
  });
  </script>
  
  <style scoped>
  /* Estilos semelhantes aos da página anterior */
  
  .student-verification-page {
    position: relative;
    width: 100%;
    padding: 60px;
    min-height: 100vh;
    background: linear-gradient(135deg, #FF6A00, #f01f84);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .form-container {
    background: white;
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .description {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1rem;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="time"],
  select,
  textarea,
  select {
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    color: #333;
  }
  
  input:focus,
  select:focus,
  textarea:focus {
    border-color: #f857a6;
    outline: none;
  }
  
  input[disabled],
  textarea[disabled] {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
  
  .error {
    color: red;
    font-size: 0.9rem;
    margin-top: 8px;
  }
  
  .button {
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #f01f84;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(248, 87, 166, 0.3);
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .button:hover {
    background-color: #ff5858;
  }
  
  .loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  .loader-container p {
    font-size: 1.3rem;
    color: #000;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .student-verification-page {
      padding: 40px;
    }
  
    .form-container {
      padding: 20px;
      width: 90%;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    .description {
      font-size: 0.9rem;
    }
  
    .form-group label,
    .form-group input,
    .form-group select,
    .form-group textarea {
      font-size: 0.9rem;
    }
  
    .form-group {
      margin-bottom: 15px;
    }
  
    .button {
      font-size: 1rem;
      padding: 10px;
    }
  }
  </style>
  