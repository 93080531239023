<template>
  <div class="reset-password-container">
    <HomeIcon />
    <div v-if="!tokenExpired">
      <form @submit.prevent="resetPassword">
        <div class="form-group">
          <h1>Redefinir Senha</h1>
          <div v-if="loading" class="loader-container">
            <div class="loader"></div>
            <p>Redefinindo senha...</p>
          </div>
          <div v-else>
            <label for="newPassword">Nova Senha</label>
            <input
              type="password"
              id="newPassword"
              v-model="state.newPassword"
              @blur="validateField('newPassword')"
              placeholder="Digite a nova senha"
            />
            <span v-if="state.errors.newPassword" class="error">
              {{ state.errors.newPassword }}
            </span>
            <button type="submit">Definir nova senha</button>
          </div>
        </div>
      </form>
    </div>
    <div v-else class="token-expired">
      <p>O token expirou. Por favor, solicite uma nova redefinição de senha.</p>
      <router-link class="back-to-login" to="/login">Voltar para o login</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted } from 'vue';
import axios from 'axios';
import * as yup from 'yup';
import { useRouter, useRoute } from 'vue-router';
import HomeIcon from '../../components/HomeIcon.vue';

export default defineComponent({
  name: 'ResetPassword',
  components: { HomeIcon },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const tokenExpired = ref(false);

    // Estado reativo com uma index signature para 'errors'
    const state = reactive({
      newPassword: '',
      errors: {} as Record<string, string>,
    });

    // Schema do Yup para validação da nova senha
    const schema = yup.object().shape({
      newPassword: yup
        .string()
        .min(6, 'A senha deve ter no mínimo 6 caracteres')
        .max(30, 'A senha deve ter no máximo 30 caracteres')
        .required('A senha é obrigatória'),
    });

    // Valida um campo individualmente e atualiza o objeto de erros
    const validateField = async (field: string) => {
      try {
        await schema.validateAt(field, state);
        state.errors[field] = '';
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          state.errors[field] = error.message;
        }
      }
    };

    // Validação completa do formulário
    const validate = async () => {
      try {
        await schema.validate({ newPassword: state.newPassword }, { abortEarly: false });
        state.errors.newPassword = '';
        return true;
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          state.errors.newPassword = error.errors.join('\n');
        }
        return false;
      }
    };

    // Função para redefinir a senha
    const resetPassword = async () => {
      if (!(await validate())) {
        return;
      }
      loading.value = true;
      const token = (route.params.token as string) || '';
      if (!token) {
        tokenExpired.value = true;
        loading.value = false;
        return;
      }
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/reset-password`, {
          token,
          newPassword: state.newPassword,
        });
        alert('Senha redefinida com sucesso!');
        router.push('/login');
      } catch (error: any) {
        console.error('Erro ao redefinir a senha:', error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          alert(`Erro: ${error.response.data.error}`);
          if (error.response.data.error.includes('Token expirado')) {
            tokenExpired.value = true;
          }
        } else {
          alert('Erro ao redefinir a senha. Por favor, tente novamente.');
        }
      } finally {
        loading.value = false;
      }
    };

    // Função para verificar a validade do token
    const verifyToken = async () => {
      const token = (route.params.token as string) || '';
      if (!token) {
        tokenExpired.value = true;
        return;
      }
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/decode-email`, { token });
      } catch (error) {
        console.error('Token inválido ou expirado:', error);
        tokenExpired.value = true;
      }
    };

    onMounted(() => {
      verifyToken();
    });

    return {
      loading,
      tokenExpired,
      state,
      resetPassword,
      validateField,
    };
  },
});
</script>

<style scoped>
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
}

.reset-password-container h1 {
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
  color: #FF4081;
  margin-bottom: 1rem;
}

form {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

button {
  text-transform: uppercase;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  border: none;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  font-weight: bold;
}

button:hover:not(:disabled) {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

.loader-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #FF4081;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1.5s linear infinite;
  margin-bottom: 10px;
}

.loader-container p {
  font-size: 1.2rem;
  color: #333;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.token-expired {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;
  color: #333;
}

.token-expired p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.back-to-login {
  display: inline-block;
  padding: 10px 20px;
  background-color: #FF4081;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s;
}

.back-to-login:hover {
  background-color: #FF4081CC;
}
</style>
