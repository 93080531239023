<template>
  <div class="select-day-page">
    <HomeIcon :showArrow="true" to="planos"/>
    <h1 v-if="!error">Escolha o dia da semana</h1>
    <div v-if="error" class="error-message">
      <p>{{ error }}</p>
      <router-link :to="{ name: 'Planos' }" class="back-button">Voltar para Seleção de Planos</router-link>
    </div>
    <div v-else class="container">
      <h2>Plano {{namePlan}}</h2>
      <select v-model="selectedDay" class="dropdown">
        <option disabled value="-1">Selecione um dia</option>
        <option v-for="(day, index) in daysOfWeek" :key="index" :value="index">
          {{ day }}
        </option>
      </select>
      <button @click="goToSelectTime" class="next-button">Próximo</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import HomeIcon from '../../components/HomeIcon.vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'SelectDayPage',
  components: {
    HomeIcon
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    const selectedDay = ref<number | null>(-1);
    const namePlan = ref<string>('');
    const error = ref<string | null>(null); // Variável para exibir a mensagem de erro

    onMounted(() => {
      const planoId = route.query.planId ? parseInt(route.query.planId as string) : null;

      if(planoId === 1){
        namePlan.value = "Mensal"
      } else if (planoId === 2){
        namePlan.value = "Trimensal"
      }

      // Verificação inicial do planoId
      if (!planoId || isNaN(planoId) || (planoId < 1 || planoId > 2)) { // Apenas IDs 1 e 2 são válidos
        error.value = 'Plano inválido. Por favor, selecione um plano válido.'; 
      }
    });

    const getNextOccurrences = (dayIndex: number, startDate: string, durationInMonths: number): string[] => {
      const start = dayjs(startDate);
      const end = start.add(durationInMonths, 'month').add(1, 'week');
      const occurrences: string[] = [];
      let nextOccurrence = start;

      while (nextOccurrence.isBefore(end) || nextOccurrence.isSame(end, 'day')) {
        occurrences.push(nextOccurrence.format('YYYY-MM-DD'));
        nextOccurrence = nextOccurrence.add(1, 'week');
      }

      return occurrences;
    };

    const goToSelectTime = async () => {
      const planoId = route.query.planId ? parseInt(route.query.planId as string) : null;

      // Validação do planoId antes de continuar
      if (!planoId || isNaN(planoId) || (planoId < 1 || planoId > 2)) {
        error.value = 'Plano inválido. Por favor, selecione um plano válido.'; 
        return;
      }

      if (selectedDay.value !== null && selectedDay.value !== -1) {
        const durationInMonths = planoId === 1 ? 1 : 3;
        const today = dayjs();
        let firstOccurrence = today.day(selectedDay.value);

        if (firstOccurrence.isBefore(today) || firstOccurrence.isSame(today)) {
          firstOccurrence = firstOccurrence.add(1, 'week');
        }

        const nextOccurrences = getNextOccurrences(selectedDay.value, firstOccurrence.format('YYYY-MM-DD'), durationInMonths);
        const startDate = nextOccurrences[0];
        const endDate = dayjs(startDate).add(durationInMonths, 'month').format('YYYY-MM-DD');

        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/generate-token-mensal`, {
            dates: nextOccurrences,
            dayIndex: selectedDay.value,
            startDate: startDate,
            endDate: endDate,
            extraDate: nextOccurrences[nextOccurrences.length - 1],
          });

          const token = response.data.token;

          router.push({
            name: 'SelectTimeMensal',
            query: {
              token,
              planId: planoId.toString(), // Passe o planId como string
            },
          });
        } catch (error) {
          console.error('Erro ao gerar o token JWT:', error);
          alert('Erro ao gerar o token. Tente novamente mais tarde.');
        }
      } else {
        alert('Por favor, selecione um dia da semana.');
      }
    };

    return {
      daysOfWeek,
      selectedDay,
      goToSelectTime,
      error,
      namePlan
    };
  }
});
</script>

<style scoped>
.select-day-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
  font-family: 'Montserrat', sans-serif;
}

.container {
  background: white;
  color: #333;
  padding: 30px 30px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  margin: 20px 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.container h2{
  padding-bottom: 10px;
}

.error-message {
  background-color: #ffe6e6;
  color: #ff0000;
  border: 1px solid #ff0000;
  padding: 20px;
  border-radius: 8px;
  font-size: 1.2rem;
  max-width: 500px;
  margin: 0 auto 20px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  display: inline-block;
}

.back-button {
  margin-top: 15px;
  display: inline-block;
  padding: 12px 25px;
  color: white;
  background-color: #ff4d4d;
  text-decoration: none;
  border-radius: 6px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-button:hover {
  background-color: #e04444;
  transform: scale(1.05);
}

h1 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.dropdown {
  width: 100%;
  padding: 15px 20px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 25px;
  appearance: none;
  background-color: #f9f9f9;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10"><path fill="%23999" d="M7 10L0 0h14z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 10px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.dropdown:focus {
  outline: none;
  border-color: #f01f84;
  box-shadow: 0 0 5px rgba(240, 31, 132, 0.5);
  background-color: #fff;
}

.next-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 10px;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  width: 100%;
}

.next-button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.next-button:active {
  transform: scale(0.98);
}

@media (max-width: 480px) {
  .container {
    padding: 30px 20px;
  }

  h1 {
    font-size: 2rem;
  }

  .dropdown {
    padding: 12px 15px;
  }

  .back-button,
  .next-button {
    padding: 12px 20px;
    font-size: 1rem;
  }
}

</style>
