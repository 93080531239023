<template>
  <div class="select-plan-page" @click="handleOutsideClick">
    <HomeIcon :showArrow="true" to="adm-dashboard" />
    <h1>Escolha Seu Plano</h1>

    <div class="container">
      <!-- Agrupamento do Input do Usuário -->
      <div class="user-search-wrapper">
        <label for="user-search">Usuário:</label>

        <!-- Input do Usuário -->
        <div class="input-with-button">
          <input
            v-model="searchQuery"
            @input="onInput"
            @focus="showDropdown = true"
            :disabled="selectedUser !== null"
            placeholder="Digite o nome ou email do usuário"
            class="search-input"
          />
          <button v-if="selectedUser" @click="deselectUser" class="alterar-cliente-button">
            Alterar Cliente
          </button>
        </div>

        <!-- Loader de Busca de Usuários -->
        <div v-if="loadingUsers" class="loader-container">
          <div class="loader"></div>
          <p>Carregando dados...</p>
        </div>

        <!-- Dropdown de Usuários -->
        <ul v-if="showDropdown && !loadingUsers && filteredUsers.length > 0" class="user-dropdown">
          <li v-for="user in filteredUsers" :key="user.id" @click.stop="selectUser(user)">
            {{ user.nome }} - {{ user.email }}
          </li>
        </ul>

        <!-- Mensagem de "Nenhum usuário encontrado" -->
        <p v-if="!loadingUsers && searchQuery.trim().length > 0 && filteredUsers.length === 0" class="no-results">
          Nenhum usuário encontrado.
        </p>
      </div>

      <!-- Seleção de Plano -->
      <div class="plan-selection">
        <label for="plan">Plano:</label>
        <select v-model="selectedPlan" id="plan">
          <option v-for="plan in plans" :key="plan.id" :value="plan.id">
            {{ plan.name }}
          </option>
        </select>
      </div>

      <div class="date-selection">
        <label for="date">Data de Início:</label>
        <input type="date" v-model="selectedDate" id="date" />
      </div>

      <div v-if="requiresTime" class="time-selection">
        <label for="time">Horário:</label>
        <select v-if="requiresTime" v-model="selectedTime" id="time">
          <option v-for="time in availableTimes" :key="time" :value="time">
            {{ time }}
          </option>
        </select>
      </div>

      <button @click="submitReservation" class="plan-button">Confirmar Reserva</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import HomeIcon from '../../components/HomeIcon.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

// Configurar o plugin
dayjs.extend(isSameOrAfter);

export default defineComponent({
  name: 'AdmCreatePlansLivre',
  components: {
    HomeIcon,
  },
  setup() {
    const router = useRouter();

    const searchQuery = ref<string>('');
    const users = ref<any[]>([]);
    const selectedUser = ref<any | null>(null);
    const loadingUsers = ref(false);
    const showDropdown = ref(false);
    const debounceTimeout = ref<ReturnType<typeof setTimeout> | null>(null);

    const selectedPlan = ref<number | null>(null);
    const selectedDate = ref<string>('');
    const selectedTime = ref<string>('');

    const plans = ref([
      { id: 1, name: 'Plano Mensal' },
      { id: 2, name: 'Plano Trimestral' },
      { id: 3, name: 'Clubinho Família' },
      { id: 4, name: 'Clubinho Individual' },
      { id: 5, name: 'Clubinho Estudante' },
      { id: 6, name: 'Clubinho Aluno' },
    ]);

    const availableTimes = ref([
      "07:00", "08:00", "09:00", "10:00", "11:00",
      "12:00", "13:00", "14:00", "15:00", "16:00",
      "17:00", "18:00", "19:00", "20:00"
    ]);

    const requiresTime = computed(() => selectedPlan.value === 1 || selectedPlan.value === 2);

    const filteredUsers = computed(() => {
      const query = searchQuery.value.toLowerCase().trim();
      return users.value.filter(
        (user) =>
          user.nome.toLowerCase().includes(query) ||
          user.email.toLowerCase().includes(query)
      );
    });

    const searchUsers = async () => {
      loadingUsers.value = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/searchClients?query=${searchQuery.value}`);
        users.value = response.data;
      } catch (error) {
        console.error("Erro ao buscar usuários:", error);
      } finally {
        loadingUsers.value = false;
      }
    };

    const onInput = () => {
      if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
      debounceTimeout.value = setTimeout(async () => {
        if (searchQuery.value.trim() === "") {
          users.value = [];
          return;
        }
        await searchUsers();
      }, 300);
    };

    const selectUser = (user: any) => {
      selectedUser.value = user;
      searchQuery.value = `${user.nome} - ${user.email}`;
      showDropdown.value = false;
    };

    const deselectUser = () => {
      selectedUser.value = null;
      searchQuery.value = "";
    };

    const handleOutsideClick = (event: Event) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".user-search-wrapper")) {
        showDropdown.value = false;
      }
    };

    const submitReservation = async () => {
      if (!selectedPlan.value || !selectedDate.value || !selectedUser.value || (requiresTime.value && !selectedTime.value)) {
        alert("Por favor, selecione todas as opções.");
        return;
      }

      const payload: Record<string, any> = {
        planId: selectedPlan.value,
        dataInicio: selectedDate.value,
        idUsuario: selectedUser.value.id,
      };

      if (requiresTime.value) {
        payload["startTime"] = selectedTime.value;
      }

      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/reservas`, payload);
        alert("Processo concluído com sucesso!");
        router.push({ name: 'adm-dashboard' });
      } catch (error) {
        console.error("Erro ao criar:", error);
        alert("Erro ao criar reserva.");
      }
    };

    // Definindo planos de reservas e clubinhos (substitua com dados reais)
    const planosReservas = ref<any[]>([
      { id: 1, dataFim: "2025-03-01" },
      { id: 2, dataFim: "2025-04-01" },
    ]);

    const planosClubinhos = ref<any[]>([
      { id: 1, dataFim: "2025-05-01" },
      { id: 2, dataFim: "2025-06-01" },
    ]);

    // Comparar datas usando o dayjs
    const startOfToday = dayjs().startOf('day'); // Definindo o começo do dia atual

    const planosMapeados = planosReservas.value
      .filter((plano: any) => dayjs(plano.dataFim).isSameOrAfter(startOfToday))
      .map((plano: any) => ({
        id: plano.id,
        tipo: "Plano",
      }));

    const clubinhosMapeados = planosClubinhos.value
      .filter((clubinho: any) => dayjs(clubinho.dataFim).isSameOrAfter(startOfToday))
      .map((clubinho: any) => ({
        id: clubinho.id,
        tipo: "Clubinho",
      }));

    return {
      selectedPlan,
      selectedDate,
      selectedTime,
      plans,
      availableTimes,
      requiresTime,
      searchQuery,
      users,
      selectedUser,
      loadingUsers,
      showDropdown,
      filteredUsers,
      onInput,
      selectUser,
      deselectUser,
      handleOutsideClick,
      submitReservation,
      planosMapeados,
      clubinhosMapeados
    };
  },
});
</script>

<style scoped>
.select-plan-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6EE7B7, #3B82F6);
  color: white;
  padding: 40px 20px;
  position: relative;
}

.container {
  background: white;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  width: 100%;
  max-width: 500px;
  position: relative;
}

.input-with-button {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 10px;
  font-size: 1.2rem;
}

.selected-user {
  color: #2563eb;
  font-size: 1.1rem;
  margin-top: 5px;
}

.alterar-cliente-button {
  background-color: #34D399;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.alterar-cliente-button:hover {
  background-color: #10B981;
}

.user-dropdown {
  margin-top: 8px;
  list-style: none;
  padding: 0;
  width: 100%;
  z-index: 10;
}

.user-dropdown li {
  padding: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1rem;
  color: #333;
  text-align: left;
}

.user-dropdown li:hover {
  background: #f0faff;
}

.plan-selection,
.date-selection,
.time-selection {
  margin-top: 20px;
}

select, input[type="date"] {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
}

.plan-button {
  background-color: #3B82F6;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  margin-top: 30px;
  cursor: pointer;
  font-size: 1.2rem;
}

.plan-button:hover {
  background-color: #2563eb;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #2563eb;
  animation: spin 1s linear infinite;
}

.loader-container p {
  font-size: 1.2rem;
  color: #000;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
