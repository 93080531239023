<template>
  <div class="container">
    <HomeIcon :showArrow="true" to="/planos" />
    <h1>ESCOLHA UM DIA</h1>
    <div class="main">
      <!-- Passa e sincroniza a data selecionada com o CalendarView -->
      <CalendarView :selectedDate="selectedDate" @update:selectedDate="handleDateSelection" />
      <div class="legend">
        <div class="legend-item">
          <span class="dot red"></span> <span>Hoje</span>
        </div>
        <div class="legend-item">
          <span class="dot blue"></span> <span>Selecionado pelo usuário</span>
        </div>
      </div>
      <div class="paragrafo">
        <p class="description">
          Para reservas avulsas, você só pode selecionar um dia por vez. Se deseja agendar em outros dias, finalize sua compra atual e volte a esta página para realizar outra reserva.
        </p>
      </div>
      <button class="next-button" @click="goToSelectTime">Próximo</button>
      <p v-if="showWarning" class="warning">Por favor, selecione uma data primeiro.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import CalendarView from '../../components/Calendar.vue';
import HomeIcon from '../../components/HomeIcon.vue';
import axios from 'axios';

export default defineComponent({
  name: 'CalendarPage',
  components: {
    CalendarView,
    HomeIcon,
  },
  setup() {
    const router = useRouter();
    const selectedDate = ref<Date | null>(null); // Define a data como nula inicialmente
    const showWarning = ref(false);

    // Função chamada ao selecionar a data no CalendarView
    const handleDateSelection = (date: Date | null) => {
      selectedDate.value = date; // Atualiza a data selecionada
    };

    // Verifica se uma data foi selecionada e redireciona
    const goToSelectTime = async () => {
      if (!selectedDate.value) {
        showWarning.value = true;
      } else {
        showWarning.value = false;
        
        try {
          // Envia a data selecionada ao back-end para gerar o token JWT
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/generate-token`, { selectedDate: selectedDate.value });
          const token = response.data.token;

          // Redireciona para a próxima página passando o token JWT como query param
          router.push({
            name: 'SelectTime',
            query: { token }, // Aqui passamos o token JWT na URL
          });
        } catch (error) {
          console.error('Erro ao gerar o token JWT:', error);
        }
      }
    };

    return {
      goToSelectTime,
      selectedDate,
      showWarning,
      handleDateSelection, // Função para lidar com a seleção de data
    };
  },
});
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  position: relative;
  padding: 20px; /* Adicionado padding para ajustar em telas menores */
}

.description {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.paragrafo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
}

.main {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  text-align: center;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.dot.red {
  background-color: red;
}

.dot.blue {
  background-color: blue;
}

.next-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  margin-top: 20px;
}

.next-button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

.warning {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}

/* Estilos para a responsividade */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .main {
    padding: 20px;
    max-width: 95%; /* Ajuste para telas menores */
  }

  .next-button {
    font-size: 0.9rem;
    padding: 10px 15px;
  }

  .paragrafo {
    max-width: 95%; /* Ajuste para telas menores */
    font-size: 0.9rem; /* Reduzindo o tamanho da fonte */
  }

  .legend {
    gap: 10px; /* Menor espaçamento entre itens */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.8rem;
  }

  .next-button {
    font-size: 0.8rem;
    padding: 8px 12px;
  }

  .legend-item {
    font-size: 0.8rem;
  }
}

</style>
